import React, { useRef, useState, useEffect, useCallback } from "react";
import styled, { keyframes, css } from "styled-components";
import DetailedSubs from "./DetailedSubs.js";
import useStore from "../../store";
import { isMobileOnly } from "react-device-detect";

const BottomBar = styled.div`
  overflow-x: auto;
  padding: 0.5rem ${isMobileOnly ? 0.25 : 1.0}rem;
  margin-top: 0.5rem;
  overflow: visible; // We need this for the dropdown menu if there's not much content.
`;

const slideIn = ($start, $end) => keyframes`
  0% {
    left: ${$start};
  }
  100% {
    left: ${$end};
  }
`;

const BottomSlideLayout = styled.div`
  max-height: ${({ $preferredHeight, $videoHeight }) =>
    "calc(max(" +
    Math.max(40, Math.min($preferredHeight, 70)) +
    "vh, " +
    $videoHeight +
    "px))"};
  min-height: ${({ $videoHeight }) =>
    $videoHeight ? "calc(100% - " + $videoHeight + "px)" : "auto"};
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: ${({ $bottom, $isVisible }) => ($isVisible ? $bottom : "-100%")};
  bottom: calc(
    ${({ $bottom, $isVisible }) => ($isVisible ? $bottom : "-100%")} - 1px
  );
  width: 100%;
  ${({ $animation }) =>
    $animation &&
    css`
      animation: ${slideIn($animation.start, $animation.end)} 0.3s ease-in-out
        forwards;
    `}
  transition: bottom 0.3s ease-in-out;
  transform: translateZ(0);
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
  background: rgba(32, 32, 32, 0.8);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`;

export default function BreakdownOverlay({
  episode,
  breakdown,
  breakdownMenuOptions,
  isVisible,
  animation,
  preferredHeight,
  isSlider = false,
  getWordBreakdownKnowledgeState,
  setWordBreakdownKnowledgeState,
}) {
  const [videoHeight, setVideoHeight] = useState(
    window.document.getElementsByTagName("video")[0]?.offsetHeight,
  );
  const showControlBar = useStore((state) => state.showControlBar);
  const overlayRef = useRef(null);
  const popoverState = useStore((state) => state.popoverState);
  const setBreakdownScrollState = useStore(
    (state) => state.setBreakdownScrollState,
  );

  useEffect(() => {
    const handleScroll = () => {
      if (overlayRef.current) {
        setBreakdownScrollState(overlayRef.current.scrollTop);
      }
    };

    const element = overlayRef.current;
    if (element) {
      element.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, [!popoverState, setBreakdownScrollState]);

  useEffect(() => {
    if (overlayRef.current) {
      overlayRef.current.scrollTo(0, 0);
    }
  }, [breakdown]);

  const fixSubtitlePaneLocation = useCallback(() => {
    const videoElement = window.document.getElementsByTagName("video")[0];
    if (videoElement) {
      setVideoHeight(videoElement.offsetHeight);
    }
  }, []);

  // resize overlay on resize
  useEffect(() => {
    window.addEventListener("resize", fixSubtitlePaneLocation);
    return () => {
      window.removeEventListener("resize", fixSubtitlePaneLocation);
    };
  }, [fixSubtitlePaneLocation]);

  // resize overlay every 100ms to make sure it's always the right size
  // we cannot simply rely on a MutationObserver to observe the video element
  // because after the src is loaded, the video element might still change size
  useEffect(() => {
    const timeout = setTimeout(fixSubtitlePaneLocation, 200);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <BottomSlideLayout
      $bottom={
        showControlBar
          ? isSlider
            ? "0px"
            : "48px" /*(the height of the control bar)*/
          : "0px"
      }
      $isVisible={isVisible}
      onAnimationEnd={animation.onEnd}
      $animation={animation}
      $preferredHeight={preferredHeight}
      $videoHeight={videoHeight}
      ref={overlayRef}
    >
      <BottomBar>
        <DetailedSubs
          episode={episode}
          breakdown={breakdown}
          isSlider={isSlider}
          breakdownMenuOptions={breakdownMenuOptions}
          getWordBreakdownKnowledgeState={getWordBreakdownKnowledgeState}
          setWordBreakdownKnowledgeState={setWordBreakdownKnowledgeState}
        />
      </BottomBar>
    </BottomSlideLayout>
  );
}
