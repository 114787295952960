import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
// eslint-disable-next-line import/extensions,import/no-unresolved
import { Swiper, SwiperSlide } from "swiper/react";
// eslint-disable-next-line import/extensions,import/no-unresolved
import "swiper/css/bundle";
import styled from "styled-components";
import useStore from "../../store.js";
import VideoBackground from "../layout/VideoBackground.js";
import BreakdownOverlay from "../breakdown/BreakdownOverlay.js";
import PopoverManager from "../breakdown/phrase/PopoverManager.js";
import Popup from "../breakdown/Popup.js";
import AnkiCreator from "../breakdown/anki/AnkiCreator.js";
import ChatPopup from "../breakdown/ChatPopup.js";
import AnkiDeckPopup from "../breakdown/anki/AnkiDeckPopup.js";
import FeedbackPopup from "../breakdown/FeedbackPopup.js";
import IconPlay from "../../assets/icons/play.svg";
import { isIOS } from "react-device-detect";

const Container = styled.div`
  overflow: hidden;
`;

const Video = styled.video`
  width: 100vw;
`;

const FlexWrapper = styled.div`
  display: flex;
  height: 100vh;
`;

const FlexGrower = styled.div`
  flex-grow: 1;
`;

const PlayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  background: url("${IconPlay}") no-repeat center;
  cursor: pointer;
  z-index: 10;
`;

const PosterBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: ${(props) => `url(${props.poster})`};
  background-size: cover;
  background-position: center;
  z-index: 5;
`;

export default function Scene({
  episode,
  fragment,
  subtitle,
  index,
  createCardCallback,
  breakdownMenuOptions,
  getWordBreakdownKnowledgeState,
  setWordBreakdownKnowledgeState,
}) {
  const subtitlesVisible = useStore((state) => state.subtitlesVisible);
  const showSubtitles = useStore((state) => state.showSubtitles);
  const hideSubtitles = useStore((state) => state.hideSubtitles);
  const sceneNumber = useStore((state) => state.sceneNumber);
  const video = useRef(null);
  const [swiper, setSwiper] = useState();
  const [playFailed, setPlayFailed] = useState(false);
  const [emptySpace, setEmptySpace] = useState(0);
  const closePopups = useStore((state) => state.closePopups);
  const popupState = useStore((state) => state.popupState);
  const setPopupState = useStore((state) => state.setPopupState);

  function closeCallback() {
    closePopups();
    setPopupState(null);
  }

  const calculateEmptySpace = useCallback(() => {
    const videoElement = video.current;
    const parent = videoElement.parentNode;
    if (parent) {
      const videoHeight = videoElement.clientHeight;
      const parentHeight = parent.clientHeight;
      const emptySpacePx = parentHeight - videoHeight;
      const emptySpaceVh = (emptySpacePx / window.innerHeight) * 100;
      setEmptySpace(emptySpaceVh);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", calculateEmptySpace);
    window.addEventListener("orientationchange", calculateEmptySpace);
    calculateEmptySpace();
    return () => {
      window.removeEventListener("resize", calculateEmptySpace);
      window.removeEventListener("orientationchange", calculateEmptySpace);
    };
  }, [calculateEmptySpace]);

  const play = useCallback(() => {
    var playPromise = video.current.play();

    if (playPromise !== undefined) {
      playPromise
        .then(() => {
          setPlayFailed(false);
        })
        .catch((error) => {
          setPlayFailed(true);
          console.error(error);
        });
    }
  }, []);

  const pause = useCallback(() => {
    if (video.current) {
      video.current.pause();
    }
  }, []);

  useEffect(() => {
    if (sceneNumber === index) {
      play();
    } else {
      pause();
      if (video.current) video.current.currentTime = 0;
    }
  }, [sceneNumber, index]);

  const isVideoPlaying = () => {
    const videoElement = video.current;
    return (
      videoElement &&
      videoElement.currentTime > 0 &&
      !videoElement.paused &&
      !videoElement.ended &&
      videoElement.readyState > 2
    );
  };

  const togglePlay = () => {
    if (isVideoPlaying()) {
      pause();
    } else {
      play();
    }
  };

  // show/hide based on subtitlesVisible
  useEffect(() => {
    if (swiper) {
      if (subtitlesVisible) {
        swiper.slideTo(1);
      } else {
        swiper.slideTo(0);
      }
    }
  }, [subtitlesVisible, swiper]);

  const videoPlayer = useMemo(
    () => (
      <Video
        playsInline
        preload="auto"
        poster={`/video/${episode.path}/.${fragment}.png`}
        ref={video}
        style={{ width: "100vw", height: "56.25vw" }} // Note: we're hard-coding 16:9 aspect ratio here
      >
        <source
          src={`/video/${episode.path}/${fragment}.webm`}
          type="video/webm; codecs=vp9,vorbis"
        />
        <source
          src={`/video/${episode.path}/${fragment}.mp4`}
          type="video/mp4"
        />
      </Video>
    ),
    [episode, fragment],
  );

  // On iOS, assets are unloaded when the tab is not visible.
  // This is a workaround to reload the video and poster when the tab is visible again.
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible" && isIOS()) {
        const videos = document.querySelectorAll("video");
        videos.forEach((videoElement) => {
          try {
            videoElement.load();
          } catch (error) {
            console.error("Error reloading video:", error);
          }
        });
        const posterBackgrounds = document.querySelectorAll(
          `[style*="background-image: url(/video/${episode.path}/.${fragment}.png)"]`,
        );
        posterBackgrounds.forEach((element) => {
          element.style.backgroundImage = `url(/video/${episode.path}/.${fragment}.png)`;
        });
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [episode.path, fragment]);

  return (
    <Container>
      <VideoBackground>
        <div style={{ width: "100vw", position: "relative" }}>
          {videoPlayer}
          {playFailed && (
            <>
              <PosterBackground
                poster={`/video/${episode.path}/.${fragment}.png`}
              />
              <PlayButton onClick={togglePlay}></PlayButton>
            </>
          )}
        </div>
      </VideoBackground>
      {popupState?.popup === "anki" && sceneNumber === index && (
        <Popup title="Create Anki Card" onClose={closePopups}>
          <AnkiCreator
            videoPath={`${episode.path}/${fragment}.mp4`}
            posterPath={`${episode.path}/.${fragment}.png`}
            episode={episode}
            parentBreakdown={subtitle}
            childBreakdown={popupState.breakdown}
            morpheme={popupState.morpheme}
            sceneNr={index}
            closeCallback={closeCallback}
            createCallback={() => createCardCallback(index)}
            setWordBreakdownKnowledgeState={setWordBreakdownKnowledgeState}
          />
        </Popup>
      )}
      {popupState?.popup === "deck" && sceneNumber === index && (
        <Popup title="Anki Deck for this Scene" onClose={closePopups}>
          <AnkiDeckPopup movieId={episode.id} breakdownIndex={subtitle.index} />
        </Popup>
      )}
      {popupState?.popup === "feedback" && sceneNumber === index && (
        <Popup title="Feedback" onClose={closePopups}>
          <FeedbackPopup
            episode={episode}
            closePopup={closePopups}
            breakdown={subtitle}
          />
        </Popup>
      )}
      {popupState?.popup === "chat" && sceneNumber === index && (
        <ChatPopup
          episode={episode}
          videoPath={episode.path + "/" + fragment + ".mp4"}
          parentBreakdown={subtitle}
          childBreakdown={popupState.breakdown}
        />
      )}
      <Swiper
        style={{ height: "100svh" }} // Swiper bug: https://github.com/nolimits4web/swiper/issues/3599
        direction="vertical"
        keyboard={{ enabled: false }}
        threshold={50}
        onSwiper={setSwiper}
        initialSlide={1}
        onSlideChange={(swiper) => {
          if (swiper.activeIndex === 1 && !subtitlesVisible) {
            showSubtitles();
          } else if (swiper.activeIndex === 0 && subtitlesVisible) {
            hideSubtitles();
          }
        }}
      >
        <SwiperSlide>
          <div style={{ height: "100lvh" }} onClick={togglePlay} />
          {/* Note: 100svh was too small and caused the breakdown pane to overlap with the video. */}
        </SwiperSlide>
        <SwiperSlide>
          <FlexWrapper>
            <FlexGrower onClick={togglePlay}></FlexGrower>
            {!!subtitle.original && (
              <>
                {sceneNumber === index && (
                  <PopoverManager
                    breakdown={subtitle}
                    getWordBreakdownKnowledgeState={
                      getWordBreakdownKnowledgeState
                    }
                    setWordBreakdownKnowledgeState={
                      setWordBreakdownKnowledgeState
                    }
                  />
                )}
                <BreakdownOverlay
                  episode={episode}
                  breakdown={subtitle}
                  breakdownMenuOptions={breakdownMenuOptions}
                  isVisible={true}
                  animation={{}}
                  preferredHeight={emptySpace}
                  isSlider={true}
                  getWordBreakdownKnowledgeState={
                    getWordBreakdownKnowledgeState
                  }
                  setWordBreakdownKnowledgeState={
                    setWordBreakdownKnowledgeState
                  }
                />
              </>
            )}
          </FlexWrapper>
        </SwiperSlide>
      </Swiper>
    </Container>
  );
}
